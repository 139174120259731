<template>
    <div class="member">
        <location :locationArr="locationArr"/>
      
        <!-- 会员风采 -->
        <div class="memberBox">
     <!--   <div class="titleTap">
            <h1>会员<span>专区</span></h1>
            <div></div>
        </div> -->
		   <!-- 导航栏 -->
			<div class="member-tabBox">
				
				<div class="member-tab-type" v-for="(item,index) in tabList" :key="index">
				  <div class="type-list-SortName" v-if="item.typeSortName != ''">【{{item.typeSortName}}】</div>
				  <div class="type-list" v-for="(list,i) in item.list" :key="i"
				  :class="list.typeId == memberActiveIndex?'active':''"
				   @click="changeMemberType(list.typeId)">
					 {{list.typeName}}
				  </div>
				</div>
			</div>
			<!-- 会员列表 -->
			<div class="member-box" v-if="memgerList.records !=null && memgerList.records.length >0 ">
				<div class="member-list">
				<div class="member-item" v-for="item,index in memgerList.records" :key="index" @click="goMemberDetail(item.fcId)">
					<div class="item-img">
						<img :src="item.fcImage" >
					</div>
					<div class="item-info">
						<p class="item-info-name">{{item.fcName}}</p>
						<p class="item-info-title" :title="item.remark">{{item.remark}}</p>
					</div>
				</div>
			</div>
			<el-pagination v-if="memgerList.total>0" style="text-align: right; margin: 60px 0;"
			               background
			               prev-text="上一页"
			               next-text="下一页"
			               layout="prev, pager, next"
			               :current-page="page"
			               @current-change="handleCurrentChange"
			               :page-size="18"
			               :total="memgerList.total">
			</el-pagination>
			</div>
			<div class="nodata" v-else>暂无数据</div>
        </div>
        <!-- 会员荣誉 -->
  
       
    </div>
</template>

<script>
    import location from '@/components/location.vue'
    // import titleTap from '@/components/titleTap.vue'

    export default {
        name: 'memberIndex',
        components: {
            location,
            // titleTap
        },
        data() {
            return {
                typeId: '',
                page: 1, // 默认页数
                locationArr: null,   //面包屑的数据
				// hyqyList:[],      // 会员企业的数据
				tabList:[], //  会员风采tab数据 
				memberActiveIndex:'',   // 会员专区tap激活标识
				memgerList:[],  // 会员成员列表  
				  
            }
        },
        mounted() {
            this.typeId = this.$route.query.typeId;
			this.getTabList()
            this.refreshData();
              // 会员企业数据
            // this.getData();
            this.typeList = [
                {typeName: '会员风采', typeId: 'hyfc'},
               ];
        },
		created() {
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						 this.$router.push({
			            path: "/mobile/member",
						query:{
							typeId:this.$route.query.typeId
						}
			        }); 
			}
		},
        methods: {
			// 面包屑
            initLocation() {
                const locationArr = [];
                let typeId = this.typeId;
                let title = "";
                if (typeId == "hyfc") {
                    title = "会员专区";
                }
                locationArr.push({"title": title, "url": "/member?typeId=" + typeId})
                this.locationArr = locationArr;
            },
            refreshData() {
                let typeId = this.$route.query.typeId;
				// let memberType = this.$route.query.memberType;
				
                if ((typeId != null && typeId != '' && typeof (typeId) != 'undefined')) {
                    this.typeId = typeId
                } 
		
                this.resData = {}
                // this.page = 1;
				// 面包屑导航
                this.initLocation()
				
				// // tab栏数据获取
				// this.getTabList()
            },
            // 分页点击事件
            handleCurrentChange(page) {
                this.page = page
					  this.$http.hyzqHyfcList({page: this.page, typeId: this.memberActiveIndex}).then(res => {
                   if (res.code == 200) {
                       this.memgerList = res.data
                   }
               })
            },
        //     getData() {
        //         switch (this.typeId) {
        //             case 'hyqy':
        //                 this.$http.hyzqHyqyList({page: this.page}).then(res => {
        //                     if (res.code == 200) {
        //                         this.hyqyList = res.data
								// // console.log(this.hyqyList)
        //                     }
        //                 })
        //                 break;
                    
        //         }
        //     },
         
            // 跳转至详情页
            goMemberDetail(id) {
                this.$router.push({path: '/member/detail', query: {id: id}})
            },
            // // 会员企业跳转
            // jumpUrl(url, dataType) {
            //     if (url && url != "") {
            //         if (dataType == 'hyqy') {
            //             window.open(url, '_blank');
            //         } else {
            //             window.location.href = url
            //         }
            //     }
            // },
			// tab栏数据获取
			getTabList(){
				this.$http.hyzqTypeList().then(res => {
				    if (res.code == 200) {
						this.tabList = res.data
						this.memberActiveIndex = this.tabList[0].list[0].typeId
						this.changeMemberType(this.memberActiveIndex)
				    }
				})
			},
			// 获取会员成员列表数据
			changeMemberType(id){
				this.memberActiveIndex = id
				this.page = 1
				this.$http.hyzqHyfcList({page: 1, typeId: id}).then(res => {
				    if (res.code == 200) {
				        this.memgerList = res.data
						// console.log(this.memgerList)
				    }
				})
			}
        },
        watch: {
            $route: 'refreshData'
        }
    }
</script>
<style scoped>
    @import '~@/assets/css/member.css';
    @import '~@/assets/css/points.css';

    .member {
        width: 1200px;
        margin: 0 auto;
    }

    .titleTap-box {
        margin-bottom: 60px;
    }
 .titleTap {
        text-align: left;
    }

    .titleTap > h1 {
        font-size: 28px;
     /*   margin-top: 56px; */
        color: #005bac;
    }

    .titleTap > h1 > span {
        color: #000000;
    }

    .titleTap > div {
        width: 80px;
        height: 4px;
        background-color: #005bac;
        margin: 16px 0 21px 0;
    }

    .care {
        margin-bottom: 60px;
    }

    .memberBoxOne {
        margin-top: 45px;
    }
	/* 暂无数据 */
	.nodata{
		min-height: 500px;
		text-align: center;
		line-height: 500px;
		font-size: 16px;
		color: #000000;
	}
</style>
